import { Show, batch, createEffect, onCleanup, onMount, splitProps, untrack, ErrorBoundary } from "solid-js";
import type { AGuardTriggerProps, AGuardState, MainProps, AGuardHelperProps, AGuardLockedRoute } from "./_model";
import { Route, type Location, useLocation } from "@solidjs/router";
import { checkGuardSteps } from "./internal";
import { _route_actions, actions } from "../SHARED/store";
import { isMatch, isWildcard, removeTrailingSlash, matcher } from ":shared/helpers/methods";
import { solidstate } from ":shared/utils/state-manager";
import { mergeRouteHref, populateRouteHref } from "../SHARED/helpers";
import { getOwner } from ":shared/helpers/solid";

let app_first_loaded = true;
let prev_location: Location<unknown> = undefined;
/** record to keep track of which bases are ignored when other bases are loaded and/or match base pattern */
const route_record: Record<string, string[]> = {
  // "/auth": ["/auth*"],
  // "/admin": ["/admin*"],
};
export default function Guarded(props: MainProps) {
  const [local] = splitProps(props, ["children", "setup", "components", "events", "options"]);
  const owner_details = getOwner();
  if (isWildcard(local.setup?.baseRoute)) {
    throw new Error(
      `GuardedRoute: route must not include any wildcards -> ${local.setup?.baseRoute} please check your setup and change it!`
    );
  } else if (route_record[local.setup?.baseRoute]) {
    if (local.options?.verbose) {
      console.warn(
        "Guarded: route base already exist, updating record for/with component -> ",
        owner_details?.tree?.[0]
      );
    }
  }
  const $loader_msg = solidstate.create(undefined as string);
  const $load_route = solidstate.create(false);
  const $guard_passed = solidstate.create(false);
  const $locked_route = solidstate.create(undefined as AGuardLockedRoute);
  //
  // let prev_location: typeof $location = undefined;
  let first_load = true;
  let navigated = false;
  route_record[local.setup?.baseRoute] = [`${removeTrailingSlash(local.setup?.baseRoute)}*`];
  if (local.options?.verbose) {
    console.log("setup abstract route :: ", local.setup?.baseRoute, " :: ", route_record);
  }
  onMount(() => {
    for (const record_base in route_record) {
      if (local.setup?.baseRoute === record_base) {
        continue;
      }
      const other_base_pattern = route_record[record_base][0];
      const local_base_pattern = route_record[local.setup?.baseRoute][0];
      if (isMatch(other_base_pattern, local_base_pattern)) {
        // console.log("base pattern :: ", local.setup?.baseRoute, " :: includes :: ", local_base_pattern, " :: ", other_base_pattern);
        route_record[local.setup?.baseRoute].push(`!${other_base_pattern}`);
      }
    }
    // console.log("mounted abstract route :: ", local.setup?.baseRoute, " :: ", route_record);
  });
  onCleanup(() => {
    /**
     * ! this affects developer experience only, not production.
     * the order to after the initial setup is
     * setup all functions -> run on mount for all functions -> run effects for all functions
     * but after on clean up is ran it goes
     * run clean up per function -> run setup per function -> run on mount per function -> run setup per functions -> run effect per function
     * which in no way allows us to recapture all bases for route_record to setup matching patterns for this base.
     * so in short, developer needs to refresh page manually.
     */
    // route_record[local.setup?.baseRoute] = undefined;
    // $load_route.set(false);
    // unloadRoute();
    // console.log("cleaned abstract route :: ", local.setup?.baseRoute);
  });

  function loadRoute(props: { $location: any }) {
    batch(() => {
      if (local.options?.verbose) {
        console.log(
          "load route base :: ",
          local.setup?.baseRoute,
          " :: exactly :: ",
          // $location.pathname,
          window.location.pathname,
          " :: $loader_msg :: ",
          $loader_msg.unwrap,
          " :: $guard_passed :: ",
          $guard_passed.unwrap
        );
      }
      if ($loader_msg.unwrap) {
        $loader_msg.set(undefined);
      }
      if (!$guard_passed.unwrap) {
        $guard_passed.set(true);
      }
      const matches = matcher(props?.$location?.pathname, route_record[local.setup?.baseRoute]);
      const subroute =
        local.setup?.baseRoute.length <= 1 ? matches[0] : matches[0]?.substring(local.setup?.baseRoute.length);
      const route_locked = $locked_route.unwrap;
      // console.log("subroute for :: ", local.setup.baseRoute, " :: ", subroute);
      if (
        typeof local?.setup?.lockedRoutes?.[subroute] === "function" &&
        local?.setup?.lockedRoutes?.[subroute]?.() === true
      ) {
        if (!route_locked) {
          $locked_route.set({ subroute });
        }
      } else {
        if (route_locked !== undefined && route_locked !== null) {
          $locked_route.set(undefined);
        }
      }
    });
  }

  function unloadRoute(props: { $location: any; triggers: any }) {
    const { $location, triggers } = props;
    if (prev_location) {
      const should_clean_up = isMatch(prev_location.pathname, route_record[local.setup?.baseRoute]);
      if (should_clean_up) {
        if (local.options?.verbose) {
          console.log("unload route :: ", local.setup?.baseRoute);
        }
        // TODO: create on clean up per route base and call it here,
        // figure out the flow and model of how developer can facilitate and/or make use of this
        local.events?.onRouteCleanup?.(triggers, {
          location: {
            current: $location,
            previous: prev_location,
          },
        });
      }
    }
    if ($guard_passed.unwrap) {
      $guard_passed.set(false);
    }
  }

  return (
    <Route
      path={local.setup?.baseRoute}
      component={(component_props) => {
        const $location = useLocation();
        const $navigate = actions.navigateHref;
        const triggers: AGuardTriggerProps = {
          loadRoute: () => loadRoute({ $location }),
          navigate: (props) => {
            const nav_props: Exclude<typeof props, string> = {
              path: typeof props === "string" ? props : props.path ?? "",
              base: typeof props === "string" ? local.setup?.baseRoute : props.base ?? local.setup?.baseRoute,
            };
            const error = $navigate(nav_props);
            // console.log("navigation error :: ", error, " :: for :: ", nav_props);
            if (error === undefined || error === null) {
              navigated = true;
            }
            return error;
          },
          redirect(props) {
            // loadRoute();
            // $loader_msg.set(undefined);
            // $guard_passed.set(true);
            return this.navigate(props);
          },
        };
        onCleanup(() => {
          first_load = true;
          batch(() => {
            if ($load_route.unwrap) {
              $load_route.set(false);
              // $loader_msg.set(`loading platform...`);
            }
            unloadRoute({ $location, triggers });
          });
        });
        createEffect(async () => {
          // @ts-ignore
          const { pathname } = $location;

          // console.log(
          //   "abstract route effect for :: ",
          //   local.setup?.baseRoute,
          //   " :: pathname -> ",
          //   pathname,
          //   " :: should mount -> ",
          //   should_mount,
          //   " :: match routes -> ",
          //   JSON.stringify(route_record[local.setup?.baseRoute])
          // );

          untrack(async () => {
            // $guard_passed.set(false);
            _route_actions.setGlobalBaseFromGuard(local.setup?.baseRoute);
            navigated = false;
            const state: AGuardState = app_first_loaded ? "app_init" : first_load ? "first_load" : "each_route";
            const helpers: AGuardHelperProps = {
              state,
              base: local.setup?.baseRoute,
              isBaseRoute: $location.pathname === local.setup?.baseRoute,
              location: {
                current: $location,
                previous: prev_location,
              },
              routeMatchBase: (base) => {
                return isMatch($location.pathname, base + "*");
              },
              routeMatch: (route, pathname?: string) => {
                if (route === local.setup?.baseRoute) {
                  console.error(
                    "GuardedRoute: use of base route as path in routeMatch is prohibited, please use isBaseRoute instead."
                  );
                  return false;
                }
                const merged_href = mergeRouteHref({ base: local.setup?.baseRoute }, route);
                const full_path = populateRouteHref(merged_href);
                const current_route_is_subset_of_full_path = isMatch(pathname ?? $location.pathname, full_path + "*");
                // console.log(
                //   "route match current route is subset ::",
                //   full_path,
                //   " :: ",
                //   current_route_is_subset_of_full_path
                // );

                return current_route_is_subset_of_full_path;
              },
              routeMatchPathname: (path) => {
                let result: boolean = isMatch(path, $location.pathname + "*");
                // console.log("route match pathname ::", route_record[local.setup?.baseRoute], " :: base to r -> ", result);
                return result;
              },
            };

            if (state === "each_route") {
              local.events?.onRouteChange?.(triggers, helpers);
            } else {
              local.events?.onRouteFirstLoad?.(triggers, helpers);
            }

            // console.log("checking route guards for :: ", local.setup?.baseRoute, " :: ", state, " :: ", event_helpers.isBaseRoute);
            // $guard_passed.set(true);
            if (local.options?.verbose) {
              console.log(
                "checking route guards for :: ",
                local.setup?.baseRoute,
                " :: exactly :: ",
                $location.pathname,
                " :: state :: ",
                state,
                " :: $loader_msg :: ",
                $loader_msg.unwrap,
                " :: $guard_passed :: ",
                $guard_passed.unwrap
              );
            }

            if (!$load_route.unwrap) {
              $load_route.set(true);
            }
            first_load = false;
            app_first_loaded = false;
            await checkGuardSteps({
              steps: local.setup.guard?.steps,
              events: {
                next: (step) => {
                  $loader_msg.set(step.loaderMsg);
                  // console.log("next step is ", step);
                  return helpers;
                },
                success: () => {
                  // console.log("surely success !!");
                  if (!local.events?.onGuardSuccess) {
                    loadRoute({ $location });
                  } else {
                    local.events?.onGuardSuccess?.(triggers, helpers);
                    let error_msg = _route_actions.getGobalRouteError();
                    if (!error_msg) {
                      if (!navigated && !$guard_passed.unwrap) {
                        error_msg = `Guarded: trigger.loadRoute haven't been called from within ${local.setup?.baseRoute} base route onGuardSuccess`;
                      }
                    }
                    if (error_msg) {
                      // if (!local.layout) {
                      // }

                      console.warn(error_msg);
                      $loader_msg.set(error_msg);
                    }
                  }
                },
                failure: (props, key) => {
                  // console.log("surely error !!");
                  let error_msg = undefined;
                  let step_error = `Guarded: step error -> ${props.error?.$$GUARD.error}`;
                  if (!local.events?.onGuardFailure) {
                    error_msg = step_error;
                    error_msg += `\n this is unhandled guard error in route base <${local.setup?.baseRoute}>`;
                    error_msg += `\n this can be handled by defining onGuardError under events prop`;
                  } else {
                    local.events?.onGuardFailure?.(triggers, { ...props, ...helpers });
                    if (_route_actions.getGobalRouteError()) {
                      // console.log("route failure ::: ", $guard_passed.unwrap);
                      error_msg = _route_actions.getGobalRouteError();
                    } else if (!navigated && !$guard_passed.unwrap) {
                      error_msg = step_error;
                      error_msg += `\n this is unhandled guard error in route base <${local.setup?.baseRoute}> with step index -> ${key}`;
                      error_msg += `\n you may have not called trigger.loadRoute or you've nvaigated to the same current route`;
                    }
                  }
                  if (local.setup.guard?.byPassErrors) {
                    loadRoute({ $location });
                    return;
                  }
                  if (error_msg) {
                    // if (!local.layout) {
                    // }
                    console.warn(error_msg);
                    $loader_msg.set(error_msg);
                  }
                },
              },
            });
            prev_location = { ...$location };
          });
        });
        return (
          <Show when={$load_route.value}>
            <ErrorBoundary
              fallback={
                local.components?.layoutErrorBoundary ??
                ((err, reset) => {
                  console.error(
                    "Guarded: FATAL :: an eror has been thrown from the layout of route base -> ",
                    local.setup.baseRoute,
                    "\nException: ",
                    err
                  );
                  return (
                    <div class="flex flex-col w-full h-full">
                      <span>an error exception has been thrown from layout of route base {local.setup?.baseRoute}</span>
                      <span>{err}</span>
                      <span onclick={() => reset()}>reset?</span>
                    </div>
                  );
                })
              }
            >
              {local.components?.layout?.({
                ...component_props,
                guard: {
                  loading: !$guard_passed.value,
                  msg: $loader_msg.value,
                  locked: $locked_route.value,
                },
              }) ?? component_props.children}
            </ErrorBoundary>
          </Show>
        );
      }}
    >
      <Route
        path="*"
        component={(component_props) => {
          return (
            <Show when={$guard_passed.value}>
              <ErrorBoundary
                fallback={
                  local.components?.errorBoundary ??
                  ((err, reset) => {
                    console.error(
                      "Guarded: FATAL :: an eror has been thrown from the setup of children components but never handled! in route base -> ",
                      local.setup?.baseRoute,
                      "\nException: ",
                      err
                    );
                    return (
                      <div class="flex flex-col w-full h-full">
                        <span>
                          an error exception has been thrown from children of route base {local.setup?.baseRoute}
                        </span>
                        <span>{err}</span>
                        <span onclick={() => reset()}>reset?</span>
                      </div>
                    );
                  })
                }
              >
                {component_props.children}
              </ErrorBoundary>
            </Show>
          );
        }}
      >
        {typeof local.children === "function" ? local.children() : local.children}
        <Route
          path="*"
          component={() => {
            return (
              <Show when={!local.components?.pageNotFound} fallback={local.components?.pageNotFound?.()}>
                <div>This page doesn't exist</div>
              </Show>
            );
          }}
        />
      </Route>
    </Route>
  );
}
